button {
	border: 1px solid white;
	background-color: rgba($color: #ffffff, $alpha: 0.15);
	color: white;
	// margin: 0 25px;
	padding: 1rem 2rem;
	transition: all 300ms ease-in-out;
	border-radius: 3px;
	backdrop-filter: blur(7px);
	font-size: 1.8rem;
	&:hover {
		cursor: pointer;
		background-color: $purplehover;
	}
}

.darkred {
	background-color: darkred;
	&:hover {
		background-color: rgb(211, 90, 90);
	}
}

.darkgreen {
	background-color: darkgreen;
	&:hover {
		background-color: rgb(34, 158, 34);
	}
}

.gold{
	background-color: $gold;
	&:hover {
		background-color: $lightgold;
	}
}
