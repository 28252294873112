nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 75px;
    background-color: rgba($color: $backgroundColor, $alpha: 0.3);
    // background-color: rgb(104, 104, 104);
    padding: 40px 9rem;
    .logo{
        font-size: 2.2rem;
        font-weight: 100;
        text-transform: uppercase;
        font-family: 'Good Timing';
    }
    .links{
        display: flex;
        justify-content: space-between;
        width: 30%;
        a{
            padding: 10px;
        }
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        padding: 30px 10px;
        .logo{
            font-size: 1.4rem;
        }
        .links{
            width: 70%;
        }
    }
}

footer{
    padding: 5px;
    text-align: center;
}