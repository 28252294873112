.building{
    @include flex-column;
	width: 100%;
	height: 100%;
	background: url("../../assets/services/services-crane-bg.png") no-repeat;
	background-position: 60% 50%;
	background-size: cover;
	position: relative;
    #crane_load {
		position: absolute;
		will-change: top, left;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 60% 50%;
		pointer-events: none;
		z-index: 0;
	}
    @media screen and (max-width: 450px){
        .box{
            .left{
                @include mobile-card-border
            }
            .right{
                display: none;                
            }
        }
    }
}

.experience {
    @include flex-column;
    width: 100%;
	height: 100%;
	background: url("../../assets/services/experience-book.png") no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
    #book{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
        object-fit: cover;
    }
    #butterfly_1, #butterfly_2, #butterfly_3, #butterfly_4, #butterfly_5, #butterfly_6, #butterfly_7, #butterfly_8, #butterfly_9, #butterfly_10, #butterfly_11 {
        position: absolute;
        top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
		pointer-events: none;
		z-index: 0;
    }
    @for $i from 1 through 6 {
		#butterfly_#{$i}{
			animation: butterfly #{randomNum(2000, 10000) + "ms"} #{"-" + randomNum(111, 4444) + "ms"} linear infinite alternate;
		}
	}
	@for $i from 7 through 11 {
		#butterfly_#{$i}{
			animation: butterfly #{randomNum(2500, 5000) + "ms"} #{"-" + randomNum(333, 5555) + "ms"} linear infinite alternate;
		}
	}
}