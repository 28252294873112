.about{
    @include flex-column;
	width: 100%;
	height: 100%;
	background: url("../../assets/about/about-tree.png") no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
    #swing {
		position: absolute;
		will-change: top, left;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
		pointer-events: none;
		z-index: 0;
	}
	#swing{
        animation: swing ease-in-out 2000ms infinite alternate;
    }
    @media screen and (max-width: 450px){
        .box{
            .left{
                @include mobile-card-border
            }
            .right{
                display: none;                
            }
        }
    }
}

.liftingup {
    @include flex-column;
    width: 100%;
	height: 100%;
	background: url("../../assets/about/liftingup-bg.png") no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	#liftingup_heart {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
		pointer-events: none;
		z-index: 4;
		animation: heartbeat 2500ms linear infinite alternate;
	}
}