.particles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	canvas {
		display: block;
		min-height: 100vh;
	}
}

.particle-containers{
	position: absolute;
	top: 0;
	left: 0;
	@include flex-center;
	width: 100%;
	height: 100%;
	.left-particles, .right-particles{
		position: relative;
		@include flex-center;
		width: 100%;
		height: 100%;
	}
	.particle-wrapper{
		position: absolute;
		top: 0;
		left: 0;
		height: 30px;
		width: 30px;
		cursor: move;
		z-index: 3;
	}
}

.hero {
    #scroll{
        z-index: 100;
        font-size: 5rem;
        position: absolute;
        top: 20px;
        left: 20px;
    }
	@include content-container;
	#triangles_vignette {
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100%;
		object-fit: cover;
		pointer-events: none;
		z-index: 0;
		@media screen and (min-width: 450px) {
			display: none;
		}
	}
	#front,
	#mid_front,
	#mid_back,
	#back {
		position: absolute;
		bottom: 0%;
		left: 0;
		width: 100%;
		height: auto;
		object-fit: cover;
		pointer-events: none;
	}
	#moon {
		position: absolute;
		width: 20vw;
		height: auto;
		top: 100px;
		right: 100px;
        @media screen and (max-width: 450px) {
			right: 10px;
			width: 30vw;
		}
	}
	#counting_stars {
		position: absolute;
		height: 20vh;
		height: clamp(1rem, 12vw, 30rem);
		width: auto;
		bottom: 10vw;
		left: 12vw;
		@media screen and (max-width: 450px) {
			height: 16vh;
			left: 1vw;
		}
	}
	h1 {
		position: absolute;
		bottom: 12vw;
		font-size: clamp(2rem, 7.5vw, 9rem);
		font-weight: 100;
		text-transform: uppercase;
		font-family: "Good Timing";
		text-shadow: 2px 2px 8px #2e2e2e;
		margin: 0;
		margin: 0 auto;
		padding: 0;
		z-index: 0;
		// @media screen and (max-width: 450px) {
		// 	font-size: 7.5vw;
		// }
	}
	.hero-headline {
		display: flex;
		flex-direction: column;
		width: 60%;
		margin-top: -8vw;
		opacity: 1;
		z-index: 10;
		@media screen and (max-width: 450px) {
			margin-top: 20vh;
		}
		h2 {
			font-size: clamp(1rem, 1.8vw, 2rem);
			margin: 0;
			margin-bottom: -0.2rem;
		}
		h3 {
			font-size: clamp(2rem, 8vw, 8rem);
			font-weight: 100;
			margin: 0;
		}
		a {
			margin: 0;
			padding: 0;
			height: 4rem;
			width: 15rem;
			margin-left: 1.5rem;
			margin-top: 20px;
		}
		button {
			position: relative;
			width: 15rem;
			height: 4rem;
			font-size: 1.6rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				height: 1.5rem;
				width: 1.5rem;
				margin: 0 10px;
			}
		}
	}
}

.box {
	@include flex-center;
	width: 100%;
	height: 100%;
    position: relative;
	z-index: 2;
	.left {
		width: 45%;
		padding: 1vw 8vw;
        position: relative;
		@include box-headings;
	}
	.right {
		width: 45%;
		padding: 1vw 8vw;
		position: relative;
		@include box-headings;
	}
	.card {
		@include flex-center-column;
		position: relative;
		min-height: 50vh;
		max-height: 600px;
		@include mobile-card-border;
        width: 100%;
		&:hover {
			backdrop-filter: blur(7px);
			.card-content {
				h5 {
					font-size: 6vw;
					transform: translateY(10px) translateX(-10px);
				}
			}
		}
		.card-content {
			@include flex-center-column;
			text-align: center;
			transition: 0.5s ease-in-out;
			animation: fadeIn 250ms ease-in-out forwards;
			padding: 2vw;
			height: 100%;
			width: 100%;
			h5 {
				position: absolute;
				top: 0px;
				right: 30px;
				font-family: "Roboto Black";
				font-size: 5vw;
				color: rgba($color: #696969, $alpha: 0.5);
				pointer-events: none;
				transition: 0.5s ease-in-out;
			}
			h6 {
				font-size: 2vw;
				font-family: "Roboto Black";
				transition: 0.5s ease-in-out;
			}
			p {
				font-size: 1.2vw;
				transition: 0.5s ease-in-out;
			}
		}
		.fadeOut {
			animation: fadeOut 250ms ease-in-out forwards;
		}
		.hidden {
			display: none;
		}
	}
}

#engineering-box {
	flex-direction: row-reverse;
	@media screen and (max-width: 450px) {
		flex-direction: column;
        justify-content: flex-start;
        .left{
            text-align: center;
            width: 100%;
            margin: 4vw auto;
            h4{
                margin: 0;
                font-size: 5vw;
            }
            h5{
                display: none;
            }
        }
        .right{
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .card{
                height: 100%;
                width: 75vw;
				@media screen and (max-width: 450px) {
					height: 80%;
				}
            }
        }
        .card-content {
			h5 {
                top: 0px;
                right: 5px;
				font-size: 12vw;
			}
			h6 {
				font-size: 6vw;
			}
			p {
				font-size: 4vw;
			}
		}
	}
}

.engineering {
	@include flex-column;
	width: 100%;
	height: 100%;
	background: url("../../assets/home/engineeringfuture.png") no-repeat;
	background-position: 35% 50%;
	background-size: cover;
	position: relative;
	#starship {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 35% 50%;
		pointer-events: none;
		z-index: 0;
	}
}

.ideas {
	@include flex-column;
	width: 100%;
	height: 100%;
	background: url("../../assets/home/idea.jpg") no-repeat;
	background-position: 70% 50%;
	background-size: cover;
	position: relative;
	h3{
		z-index: 1;
	}
	#lightbulb {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 70% 50%;
		pointer-events: none;
		z-index: 0;
		animation: breathe 5000ms linear infinite alternate;
	}
	.particle{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 0px;
		height: 0px;
		border-radius: 50%;
		background-color: rgb(255, 255, 255);
		box-shadow:
			0 0 15px 8px #fff,  /* inner white */
			0 0 15px 8px rgb(186, 135, 245), /* middle magenta */
			0 0 35px 23px rgb(119, 46, 255); /* outer cyan */
	}
	#rp1-ideas{
		top: 50%;
		left: 10%;
		animation: glowPop 5000ms linear infinite forwards;
	}
	#rp2-ideas{
		top: 70%;
		left: 70%;
		animation: glowPop 5000ms -2000ms linear infinite forwards;
	}
	#rp3-ideas{
		top: 65%;
		left: 20%;
		animation: glowPop 5000ms -3000ms linear infinite forwards;
	}
	#rp4-ideas{
		top: 45%;
		left: 80%;
		animation: glowPop 5000ms -4000ms linear infinite forwards;
	}
	#rp5-ideas{
		top: 22%;
		left: 20%;
		animation: glowPop 5000ms -1500ms linear infinite forwards;
	}
	#rp6-ideas{
		top: 23%;
		left: 55%;
		animation: glowPop 5000ms -3500ms linear infinite forwards;
	}
	#rp7-ideas{
		top: 25%;
		left: 75%;
		animation: glowPop 5000ms -500ms linear infinite forwards;
	}
    @media screen and (max-width: 450px){
        .box{
            .left{
                @include mobile-card-border
            }
            .right{
                display: none;                
            }
        }
    }
}

.connecting {
	@include flex-column;
	width: 100%;
	height: 100%;
	background: url("../../assets/home/connecting-bg-np.jpg") no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	z-index: 0;
    #heart {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
		pointer-events: none;
		z-index: 4;
	}
	.particle{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 0px;
		height: 0px;
		border-radius: 50%;
		background-color: rgb(255, 255, 255);
		box-shadow:
			0 0 7px 4px #fff,  /* inner white */
			0 0 7px 4px rgb(186, 135, 245), /* middle magenta */
			0 0 17px 11px rgb(119, 46, 255); /* outer cyan */
	}
	.particle-wrapper:nth-child(3n){
		.particle{
			box-shadow:
			0 0 4px 2px #fff,  /* inner white */
			0 0 4px 2px rgb(186, 135, 245), /* middle magenta */
			0 0 9px 5px rgb(119, 46, 255); /* outer cyan */
		}
	}
	@for $i from 1 through 20 {
		#lp#{$i}-connecting{
			top: #{randomNum(15, 90) + "%"};
			left: #{randomNum(10, 90) + "%"};
			animation: twinkle #{randomNum(2000, 6000) + "ms"} #{"-" + randomNum(500, 5000) + "ms"} linear infinite alternate;
		}
		#rp#{$i}-connecting{
			top: #{randomNumExclude(15, 90, 40, 72) + "%"};
			left: #{randomNum(10, 90) + "%"};
			animation: twinkle #{randomNum(2000, 6000) + "ms"} #{"-" + randomNum(500, 5000) + "ms"} linear infinite alternate;
		}
	}
    .box{
        .right{
            text-align: center;
            h4{
                text-transform: none;
            }
        }
    }
    @media screen and (max-width: 450px){
        .box{
			z-index: 5;
            .left{
                display: none;
            }
            .right{
                @include mobile-card-border;
				z-index: 50;
            }
        }
    }
}

.career {
	@include flex-column;
	width: 100%;
	height: 100%;
	background: url("../../assets/home/stairs-bg.jpg") no-repeat;
	background-position: 55% 50%;
	background-size: cover;
	position: relative;
    #stair1, #stair3, #stair5, #stair7, #stair9, #stair11 {
		position: absolute;
		will-change: top, left;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 55% 50%;
		pointer-events: none;
		z-index: 0;
	}
    #stair2, #stair4, #stair6, #stair8, #stair10 {
		position: absolute;
		will-change: bottom, right;
		bottom: 0;
        right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 55% 50%;
		pointer-events: none;
		z-index: 0;
	}
    #stepping_up {
		position: absolute;
		height: 20vh;
		height: clamp(1rem, 12vw, 30rem);
		width: auto;
		bottom: 5vh;
		left: 50vw;
		@media screen and (max-width: 450px) {
			height: 16vh;
		}
	}
    @media screen and (max-width: 450px){
        .box{
            .left{
                @include mobile-card-border
            }
            .right{
                display: none;                
            }
        }
    }
}

.contact {
	@include flex-column;
	width: 100%;
    height: 100%;
	min-height: 100vh;
	background: url("../../assets/home/contact-bg.jpg") no-repeat;
	background-position: 50% 50%;
	background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 0;
    #heart1, #heart2, #heart3, #heart4, #heart5, #heart6 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: 50% 50%;
		pointer-events: none;
		z-index: 0;
	}
    #heart1{
        animation: floatingHigher 8000ms infinite forwards;
    }
    #heart2{
        animation: floating 5500ms -500ms infinite forwards;
    }
    #heart3{
        animation: floating 9600ms -750ms infinite forwards;
    }
    #heart4{
        animation: floatingHigher 10000ms -150ms infinite forwards;
    }
    #heart5{
        animation: floating 6000ms -750ms infinite forwards;
    }
    #heart6{
        animation: floating 8000ms -1550ms infinite forwards;
    }
    .contact-header{
        @include flex-center-column;
    }
    .box{
        .left {
            z-index: 10;
            padding: 1vw 6vw;
            .close-button{
                font-size: 3rem;
                font-family: 'Roboto Black';
                position: absolute;
                left: 15px;
                transform: all 300ms ease-in-out;
            }
        }
        .right{
            text-align: center;
            h4{
                text-transform: none;
            }
            #contact_envelope{
                width: 100%;
                height: auto;
            }
        }
    }
    @media screen and (max-width: 450px){
        .box{
            .left{
                @include mobile-card-border;
                justify-content: center;
            }
            .modal{
                width: 95vw;
            }
            .right{
                display: none;
            }
        }
    }
    @media screen and (max-height: 568px) {
        overflow: auto;
        justify-content: flex-start;
        text-align: center;
        -ms-overflow-style: none;
        scrollbar-width: none;
        .box{
            margin-top: 6rem;
            padding-bottom: 6rem;
        }
    }
}

.contact::-webkit-scrollbar {
    display: none;
}

.contact-form{
    @include flex-column;
    width: 100%;
    height: 100%;
    z-index: 1;
    input::placeholder, textarea::placeholder {
        color: rgb(209, 200, 224);
        opacity: 1;
    }
    .contact-form-label{
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .contact-form-input{
        max-width: 830px;
        width: 100%;
        height: 42px;
        border-radius: 4px;
        border: 1px solid rgb(197, 197, 197);
        font-size: clamp(1.4rem, 2vw, 2rem);
        margin: 15px 0;
        padding-left: 15px;
        font-family: 'Roboto Light';
        color: white;
        background-color: rgba($color: #ffffff, $alpha: 0.15);
        backdrop-filter: blur(5px);
    }
    .contact-form-half{
        width: 100%;
        text-align: center;
        input{
            width: 100%;
        }
        @media screen and (max-width: 1100px){
            // border: 1px solid red;
            margin: 0;
            padding: 0;
            input{
                width: 100%;
                max-width: 830px;
                margin: 0;
                margin-bottom: 15px;
                // padding: 0;
            }
        }
    }
    #contact_question{
        max-width: 830px;
        height: 100%;
        font-family: 'Roboto Light';
        padding: 10px;
    }
    .button{
        border: 1px solid white;
        background-color: rgba($color: #ffffff, $alpha: 0.15);
        color: white;
        // margin: 0 25px;
        padding: 1rem 2rem;
        transition: all 300ms ease-in-out;
        border-radius: 3px;
        backdrop-filter: blur(7px);
        font-size: 1.8rem;
        &:hover {
            cursor: pointer;
            background-color: $purplehover;
        }
    }
    .error{
        padding-top: 20px;
        margin: 0 auto;
        text-align: center;
        font-size: 0.8rem;
    }
}