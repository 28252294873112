// @font-face {
// 	font-family: "Good Timing";
// 	font-style: normal;
// 	font-weight: normal;
// 	src: local("Good Timing BD"),
// 		url("../../assets/fonts/GoodTimesRegular.woff") format("woff");
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "Roboto Light";
// 	font-style: normal;
// 	font-weight: normal;
// 	src: local("Roboto Light"),
// 		url("../../assets/fonts/Roboto-Light.woff") format("woff");
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "Roboto Thin";
// 	font-style: normal;
// 	font-weight: normal;
// 	src: local("Roboto Thin"),
// 		url("../../assets/fonts/Roboto-Thin.woff") format("woff");
// 	font-display: swap;
// }

// @font-face {
// 	font-family: "Roboto Black";
// 	font-style: normal;
// 	font-weight: normal;
// 	src: local("Roboto Black"),
// 		url("../../assets/fonts/Roboto-Black.woff") format("woff");
// 	font-display: swap;
// }

html {
	font-size: 62.5%;
    height: 100%;
    overflow: auto;
}

body {
	margin: 0;
	font-family: "Roboto Thin", -apple-system, BlinkMacSystemFont, "", "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #111;
    height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

textarea,
input {
	font-family: "Roboto Light", Calibri, "Trebuchet MS", sans-serif;
	font-size: 0.9rem;
	border-radius: 3px;
	border-width: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	z-index: 10;
}

h3 {
	font-size: clamp(2vw, 8vw, 8rem);
	font-weight: 100;
	margin: 3rem 0;
	text-shadow: 2px 2px 8px #2e2e2e;
}

h4 {
	font-size: clamp(1.6rem, 4vw, 3rem);
	font-weight: 1000;
	margin: 2rem 0;
}

h5 {
	font-size: clamp(1.4rem, 2vw, 2rem);
	font-weight: 100;
	margin: 1rem 0;
}

h6 {
	font-size: clamp(1.4rem, 2vw, 2rem);
	font-weight: 100;
	margin: 1rem 0;
}

p {
	font-size: clamp(1.4rem, 2vw, 2rem);
	font-weight: 100;
    margin: 1vh 0;
	line-height: 3rem;
}

b {
	font-family: 'Roboto Light';
}

h1:focus{
    outline: 0px solid white;
}

button:focus{
    outline: 0px;
}
    
div:focus{
    outline: 0px solid white;
}

*:focus {
    outline: 1px solid rgba($color: #695b8a, $alpha: 1.0);
}

input:focus{
    background-color: rgba($color: #695b8a, $alpha: 1.0)
    
}

::-webkit-scrollbar {
	width: 20px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #afafaf;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #dddddd;
}