.careers{
    @include flex-column;
	width: 100%;
	height: 100%;
	background: url("../../assets/careers/careers-bg.png") no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
    @media screen and (max-width: 450px){
        .box{
            .left{
                @include mobile-card-border
            }
            .right{
                display: none;                
            }
        }
    }
	.tile-container{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 1%;
		.tile{
			background-color: rgba(200, 200, 200, 0.3);
			backdrop-filter: blur(7px);
			border: 1px solid rgb(216, 215, 215);
			border-radius: 5px;
			width: 28%;
			min-width: 320px;
			min-height: 300px;
			height: 33%;
			margin: 2%;
			padding: 2%;
			text-align: center;
			transition: all 500ms ease-in-out;
			&:hover{
				transform: scale(1.1);
				background-color: rgba(200, 200, 200, 0.8);
				
			}
			h5{
				font-size: 3rem;
				font-family: 'Roboto Black';
			}
		}
		#last-tile{
			border: none;
			background-color: rgba(200, 200, 200, 0);
			backdrop-filter: none;
		}
	}
}