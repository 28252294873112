$backgroundColor: #181428;
$backgroundColorDark: #0f0729;
$backgroundGradient: linear-gradient(148deg, rgba(255,255,255,1) 0%, rgb(211, 211, 211) 100%);
$orange: #e89624;
$gold: #FEBD11;
$lightgold: #fdd052;
$purple: #4b0082;
$blue: #26408b;
$green: #3ba99c;
$smoke: #eff7f6;
$purplehover: #9645dd;
$lightpurple: #592a83;
$darkpurple: #3b3054;
$lightpurplebox:  rgba(87, 12, 131, 0.055);
$backgroundImage: url('../../assets/home/triangles-vignette.png');

@function randomNum($min, $max) {
    $rand: random();
    $randomNum: $min + floor($rand * (($max - $min) + 1));
    @return $randomNum;
}

@function randomNumExclude($min, $max, $top, $bottom) {
    $rand: random();
    $randomNum: $min + floor($rand * (($max - $min) + 1));
    @while $randomNum > $top and $randomNum < $bottom {
        $rand: random();
        $randomNum: $min + floor($rand * (($max - $min) + 1));
    }
    @return $randomNum;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin box-headings {
    h4 {
        text-transform: uppercase;
    }
    h5 {
        transition: all 0.5s ease-in-out;
        &:hover {
            font-weight: 900;
            letter-spacing: 1px;
            cursor: pointer;
        }
    }
    h6, .title{
        font-family: 'Roboto Black';
        font-size: 3vw;
    }
    .selected {
        font-weight: 900;
        letter-spacing: 1px;
        pointer-events: none;
    }
    button{
        margin: 2rem auto;
    }
}

.hide-desktop {
    @media screen and (min-width: 450px) {
        display: none;
    }
}

.hide-mobile {
    @media screen and (max-width: 451px) {
        display: none;
    }
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.margin-top-mobile{
    @media screen and (max-width: 450px) {
        h3{
            margin-bottom: 0;
        }
        .box{
            margin-top: -15vw;
        }
    }
}

@mixin mobile-card-border {
    width: 75vw;
    text-align: center;
    background: rgba($color: #696969, $alpha: 0.2);
    box-shadow: 10px 7px 25px rgba($color: #a0a0a0, $alpha: 0.3);
    border-radius: 15px;
    border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
    border-left: 1px solid rgba($color: #fff, $alpha: 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    overflow: hidden;
    text-align: center;
    // transition: 0.5s ease-in-out;
    // animation: fadeIn 250ms ease-in-out forwards;
    padding: 2vw;
    position: relative;
    h4{
        font-size: 5vw;
        font-family: 'Roboto Black';
    }
    h5 {
        position: absolute;
        top: -10px;
        right: 5px;
        font-size: 12vw;
        font-family: "Roboto Black";
        color: rgba($color: #696969, $alpha: 0.3);
        pointer-events: none;
        transition: 0.5s ease-in-out;
    }
    h6 {
        @media screen and (max-width: 450px){
            margin-top: 12vw;
        }
        font-size: 6vw;
        font-family: "Roboto Black";
        transition: 0.5s ease-in-out;
    }
    .title {
        @media screen and (max-width: 450px){
            margin-top: 12vw;
            margin-bottom: 2vw;
        }
        font-size: 6vw;
        font-family: "Roboto Black";
        transition: 0.5s ease-in-out;
    }
    p {
        font-size: 4vw;
        transition: 0.5s ease-in-out;
    }
}

@mixin content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    min-height: 568px;
    position: relative;
    overflow: hidden;
    @media screen and (max-height: 568px) {
        overflow: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        .box{
            margin-top: 6rem;
            padding-bottom: 6rem;
        }
        article{
            overflow: auto;
            overflow-x: hidden;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
        article::-webkit-scrollbar {
            display: none;
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

@keyframes popIn {
    0% {
        transform: scale(0);
        // opacity: 0;
    }
    80% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }
    45% {
        transform: translateY(-29px)
    }
    50% {
        transform: translateY(-30px);
    }
    55% {
        transform: translateY(-29px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes floatingHigher {
    0% {
        transform: translateY(0);
    }
    45% {
        transform: translateY(-54px)
    }
    50% {
        transform: translateY(-55px);
    }
    55% {
        transform: translateY(-54px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes glowPop {
    0%{
        transform: matrix( 0.2, 0, 0, 0.2, 0, 0);
        opacity: 0;
    }
    90%{
        transform: matrix( 1.3, 0, 0, 1.3, 0, -95);
        opacity: 1;
    }
    100%{
        transform: matrix( 1.5, 0, 0, 1.5, 0, -110);
        opacity: 0;
    }
}

@keyframes breathe {
    0%{
        transform: scale(1);
    }
    100%{
        transform: translateX(-1%) translateY(-1%) scale(1.04);
    }
}

@keyframes butterfly {
    0%{
        transform: scale(1);
    }
    100%{
        transform: translateX(1%) translateY(-1%) scale(1.04);
    }
}

@keyframes twinkle {
    0%{
        transform: scale(1) translateY(0px);
    }
    25%{
        transform: scale(0.5) translateY(-2px);
    }
    50%{
        transform: scale(0.7) translateY(-1px);
    }
    75%{
        transform: scale(1.2) translateY(-3px);
    }
    100%{
        transform: scale(1.5) translateY(-2px);
    }
}

@keyframes swing {
    0% {
        transform: rotate(3deg);
        transform-origin: 44% 44%;
    }
    100% {
        transform: rotate(-3deg);
        transform-origin: 44% 44%;
    }
}

@keyframes heartbeat {
    0%{
        transform: scale(1);
    }
    33% {
        transform: translateX(-6px) scale(1.02);
    }
    50%{
        transform: translateX(0px) scale(1);
    }
    75% {
        transform: translateX(-10px) scale(1.03)
    }
    100%{
        transform: translateX(-4px) scale(1.01);
    }
}